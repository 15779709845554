<template>
  <div id="apipe-cloud-course-page">
    <top-nav-new />

    <div class="apipe-cloud-course-container-office">
      <div class="top-banner d-none d-lg-block">
        <div class="container" style="text-align: left">
          <h2>伟班，一站式数智化办公管家</h2>
          <div class="tit">
            实现办公数智化，管理一体化，让企业轻松应对各种挑战，快速响应市场变化。
          </div>
          <div class="tit">加入伟班平台，开启数字化办公新时代！</div>
          <div class="exp" @click="handleTest">立即体验</div>
        </div>
      </div>
      <div class="mobile-banner d-block d-lg-none">
        <div class="container" style="text-align: left">
          <h4>伟班，一站式数智化办公管家</h4>
          <div class="tit">实现办公数智化，管理一体化，</div>
          <div class="tit">让企业轻松应对各种挑战，快速响应市场变化。</div>
          <div class="tit">加入伟班平台，开启数字化办公新时代！</div>
          <!-- <span class="exp" @click="handleTest">立即体验</span> -->
        </div>
      </div>

      <div class="customeValue">
        <h4 class="pro-tit">客户价值</h4>
        <h6 class="pro-tits">伟班是一种工作方式，更高效的工作方式</h6>
        <div class="container text-center">
          <div class="row row-cols-1 row-cols-lg-4 g-2 g-lg-3">
            <div class="col">
              <div class="p-3">
                <img src="@/assets/newSite/office/icon1.png" alt="" />
                <h6>提高沟通效率</h6>
                <p>
                  伟班提供多种沟通方式，包括即时通讯、视频会议等，方便团队成员实时交流和协作
                </p>
              </div>
            </div>
            <div class="col">
              <div class="p-3">
                <img src="@/assets/newSite/office/icon2.png" alt="" />
                <h6>优化工作流程</h6>
                <p>
                  伟班可以帮助企业规范工作流程，实现管理一体化，提高工作效率
                </p>
              </div>
            </div>
            <div class="col">
              <div class="p-3">
                <img src="@/assets/newSite/office/icon3.png" alt="" />
                <h6>降低运营成本</h6>
                <p>
                  伟班可以优化企业的管理流程和协作方式，减少重复劳动和沟通成本，从而降低企业的运营成本，提高企业的盈利能力
                </p>
              </div>
            </div>
            <div class="col">
              <div class="p-3">
                <img src="@/assets/newSite/office/icon4.png" alt="" />
                <h6>信息整合</h6>
                <p>
                  伟班集成了多种应用和工具，可以将各种信息整合在一个平台上，方便团队成员查找和管理
                </p>
              </div>
            </div>
            <div class="col">
              <div class="p-3">
                <img src="@/assets/newSite/office/icon4.png" alt="" />
                <h6>个性化定制及扩展</h6>
                <p>
                  伟班可以根据企业的具体需求进行个性化定制，及与第三方系统对接，满足不同企业的办公需求
                </p>
              </div>
            </div>
            <div class="col">
              <div class="p-3">
                <img src="@/assets/newSite/office/icon6.png" alt="" />
                <h6>支持多端使用</h6>
                <p>
                  伟班支持多种设备和终端的使用，包括PC、手机、平板等，方便用户随时随地进行办公协作
                </p>
              </div>
            </div>
            <div class="col">
              <div class="p-3">
                <img src="@/assets/newSite/office/icon7.png" alt="" />
                <h6>安全保障</h6>
                <p>
                  伟班采用严格的数据加密和权限管理机制，保障企业数据的安全和隐私
                </p>
              </div>
            </div>
            <div class="col">
              <div class="p-3">
                <img src="@/assets/newSite/office/icon8.png" alt="" />
                <h6>支持多行业应用</h6>
                <p>
                  伟班是一款通用的协作办公工具，各行各业的企业都可以使用伟班来提高企业的协同效率和竞争力
                </p>
              </div>
            </div>
          </div>

          <div class="row"></div>
        </div>
      </div>

      <div class="sys container">
        <h4 class="pro-tit">系统架构</h4>
        <img src="@/assets/newSite/office/sys(1).png" style="width: 100%" alt="" />
      </div>

      <div class="pc-function d-none d-lg-block">
        <div class="container">
          <h4 class="pro-tit">
            功能应用
            <span
              :class="[
                'opt',
                'el-icon-arrow-left',
                activeName != 0 ? 'activ' : 'dis',
              ]"
              @click="handleLeft"
            ></span>
            <span
              :class="[
                'opt',
                'el-icon-arrow-right',
                activeName != 6 ? 'activ' : 'dis',
              ]"
              @click="handleRight"
            ></span>
          </h4>
          <div class="main-cot">
            <el-tabs
              class="myTab"
              v-model="activeName"
              @tab-click="handleClick"
            >
              <el-tab-pane label="即时通讯" name="0">
                <div class="wrap">
                  <div style="flex: 1; padding: 10px">
                    <span class="sp">即时通讯</span>
                    <p class="w-cot">
                      支持在线实时沟通、视频会议等多种沟通方式，方便团队成员实时交流
                    </p>
                    <button @click="handleTest" type="button" class="btn btn-primary btn-sm">
                      立即体验
                    </button>
                  </div>
                  <div style="flex: 1; padding: 10px">
                    <img
                      src="@/assets/newSite/office/contact.png"
                      style="height: 330px"
                      alt=""
                    />
                  </div>
                </div>
              </el-tab-pane>
              <el-tab-pane label="视频会议" name="1">
                <div class="wrap">
                  <div style="flex: 1; padding: 10px; text-align: left">
                    <span class="sp">视频会议</span>
                    <p class="w-cot">
                      支持高清视频通话和屏幕共享，方便团队成员远程协作
                    </p>
                    <button @click="handleTest" type="button" class="btn btn-primary btn-sm">
                      立即体验
                    </button>
                  </div>
                  <div style="flex: 1; padding: 10px">
                    <img
                      src="@/assets/newSite/office/ship.png"
                      style="height: 330px"
                      alt=""
                    />
                  </div>
                </div>
              </el-tab-pane>
              <el-tab-pane label="任务管理" name="2">
                <div class="wrap">
                  <div style="flex: 1; padding: 10px">
                    <span class="sp">任务管理</span>
                    <p class="w-cot">
                      可以创建任务、分配责任人、设置截止日期等，帮助团队成员更好地规划和执行工作
                    </p>
                    <button @click="handleTest" type="button" class="btn btn-primary btn-sm">
                      立即体验
                    </button>
                  </div>
                  <div style="flex: 1; padding: 10px">
                    <img
                      src="@/assets/newSite/office/task.png"
                      style="height: 330px"
                      alt=""
                    />
                  </div>
                </div>
              </el-tab-pane>
              <el-tab-pane label="文件共享" name="3">
                <div class="wrap">
                  <div style="flex: 1; padding: 10px">
                    <span class="sp">文件共享</span>
                    <p class="w-cot">
                      支持上传、下载、预览多种格式的文件，方便团队成员共享资料和协同工作
                    </p>
                    <button @click="handleTest" type="button" class="btn btn-primary btn-sm">
                      立即体验
                    </button>
                  </div>
                  <div style="flex: 1; padding: 10px">
                    <img
                      src="@/assets/newSite/office/file.png"
                      style="height: 330px"
                      alt=""
                    />
                  </div>
                </div>
              </el-tab-pane>
              <el-tab-pane label="项目管理" name="4">
                <div class="wrap">
                  <div style="flex: 1; padding: 10px">
                    <span class="sp">项目管理</span>
                    <p class="w-cot">
                      可以创建项目、分配任务、制定计划和进度等，帮助企业实现项目化管理和高效协作
                    </p>
                    <button @click="handleTest" type="button" class="btn btn-primary btn-sm">
                      立即体验
                    </button>
                  </div>
                  <div style="flex: 1; padding: 10px">
                    <img
                      src="@/assets/newSite/office/pro.png"
                      style="height: 330px"
                      alt=""
                    />
                  </div>
                </div>
              </el-tab-pane>
              <el-tab-pane label="审批流程" name="5">
                <div class="wrap">
                  <div style="flex: 1; padding: 10px">
                    <span class="sp">审批流程</span>
                    <p class="w-cot">
                      可以实现自定义审批流程，包括请假、费用申请、合同签署等多种场景，简化企业审批流程，提高效率
                    </p>
                    <button @click="handleTest" type="button" class="btn btn-primary btn-sm">
                      立即体验
                    </button>
                  </div>
                  <div style="flex: 1; padding: 10px">
                    <img
                      src="@/assets/newSite/office/reject.png"
                      style="height: 330px"
                      alt=""
                    />
                  </div>
                </div>
              </el-tab-pane>
              <el-tab-pane label="数据分析" name="6">
                <div class="wrap">
                  <div style="flex: 1; padding: 10px">
                    <span class="sp">数据分析</span>
                    <p class="w-cot">
                      提供多种数据分析工具和报表，帮助企业了解团队的工作状态和效率，为决策提供数据支持
                    </p>
                    <button @click="handleTest" type="button" class="btn btn-primary btn-sm">
                      立即体验
                    </button>
                  </div>
                  <div style="flex: 1; padding: 10px">
                    <img
                      src="@/assets/newSite/office/data.png"
                      style="height: 330px"
                      alt=""
                    />
                  </div>
                </div>
              </el-tab-pane>
            </el-tabs>
          </div>
        </div>
      </div>

      <div class="mobile-function d-block d-lg-none">
        <div class="container">
          <h4 class="pro-tit">功能应用</h4>
          <div class="main-cot">
            <el-tabs
              class="myTab"
              v-model="activeName"
              @tab-click="handleClick"
            >
              <el-tab-pane label="即时通讯" name="0">
                <div class="wrap">
                  <div style="flex: 1; padding: 10px">
                    <span class="sp">即时通讯</span>
                    <p class="w-cot">
                      支持在线实时沟通、视频会议等多种沟通方式，方便团队成员实时交流
                    </p>
                    <p style="text-align: center">
                      <!-- <button
                        @click.stop="
                          () => {
                            window.open('https://apipecloud.veiban.com/login');
                          }
                        "
                        type="button"
                        class="btn btn-primary btn-sm"
                      >
                        立即体验
                      </button> -->
                    </p>
                  </div>
                  <div style="flex: 1; padding: 10px">
                    <img
                      src="@/assets/newSite/office/contact.png"
                      style="width: 100%; height: auto"
                      alt=""
                    />
                  </div>
                </div>
              </el-tab-pane>
              <el-tab-pane label="视频会议" name="1">
                <div class="wrap">
                  <div style="flex: 1; padding: 10px; text-align: left">
                    <span class="sp">视频会议</span>
                    <p class="w-cot">
                      支持高清视频通话和屏幕共享，方便团队成员远程协作
                    </p>
                    <p style="text-align: center">
                      <!-- <button type="button" class="btn btn-primary btn-sm">
                        立即体验
                      </button> -->
                    </p>
                  </div>
                  <div style="flex: 1; padding: 10px">
                    <img
                      src="@/assets/newSite/office/ship.png"
                      style="width: 100%"
                      alt=""
                    />
                  </div>
                </div>
              </el-tab-pane>
              <el-tab-pane label="任务管理" name="2">
                <div class="wrap">
                  <div style="flex: 1; padding: 10px">
                    <span class="sp">任务管理</span>
                    <p class="w-cot">
                      可以创建任务、分配责任人、设置截止日期等，帮助团队成员更好地规划和执行工作
                    </p>
                    <p style="text-align: center">
                      <!-- <button type="button" class="btn btn-primary btn-sm">
                        立即体验
                      </button> -->
                    </p>
                  </div>
                  <div style="flex: 1; padding: 10px">
                    <img
                      src="@/assets/newSite/office/task.png"
                      style="width: 100%"
                      alt=""
                    />
                  </div>
                </div>
              </el-tab-pane>
              <el-tab-pane label="文件共享" name="3">
                <div class="wrap">
                  <div style="flex: 1; padding: 10px">
                    <span class="sp">文件共享</span>
                    <p class="w-cot">
                      支持上传、下载、预览多种格式的文件，方便团队成员共享资料和协同工作
                    </p>
                    <p style="text-align: center">
                      <!-- <button type="button" class="btn btn-primary btn-sm">
                        立即体验
                      </button> -->
                    </p>
                  </div>
                  <div style="flex: 1; padding: 10px">
                    <img
                      src="@/assets/newSite/office/file.png"
                      style="width: 100%"
                      alt=""
                    />
                  </div>
                </div>
              </el-tab-pane>
              <el-tab-pane label="项目管理" name="4">
                <div class="wrap">
                  <div style="flex: 1; padding: 10px">
                    <span class="sp">项目管理</span>
                    <p class="w-cot">
                      可以创建项目、分配任务、制定计划和进度等，帮助企业实现项目化管理和高效协作
                    </p>
                    <p style="text-align: center">
                      <!-- <button type="button" class="btn btn-primary btn-sm">
                        立即体验
                      </button> -->
                    </p>
                  </div>
                  <div style="flex: 1; padding: 10px">
                    <img
                      src="@/assets/newSite/office/pro.png"
                      style="width: 100%"
                      alt=""
                    />
                  </div>
                </div>
              </el-tab-pane>
              <el-tab-pane label="审批流程" name="5">
                <div class="wrap">
                  <div style="flex: 1; padding: 10px">
                    <span class="sp">审批流程</span>
                    <p class="w-cot">
                      可以实现自定义审批流程，包括请假、费用申请、合同签署等多种场景，简化企业审批流程，提高效率
                    </p>
                    <p style="text-align: center">
                      <!-- <button type="button" class="btn btn-primary btn-sm">
                        立即体验
                      </button> -->
                    </p>
                  </div>
                  <div style="flex: 1; padding: 10px">
                    <img
                      src="@/assets/newSite/office/reject.png"
                      style="width: 100%"
                      alt=""
                    />
                  </div>
                </div>
              </el-tab-pane>
              <el-tab-pane label="数据分析" name="6">
                <div class="wrap">
                  <div style="flex: 1; padding: 10px">
                    <span class="sp">数据分析</span>
                    <p class="w-cot">
                      提供多种数据分析工具和报表，帮助企业了解团队的工作状态和效率，为决策提供数据支持
                    </p>
                    <p style="text-align: center">
                      <!-- <button type="button" class="btn btn-primary btn-sm">
                        立即体验
                      </button> -->
                    </p>
                  </div>
                  <div style="flex: 1; padding: 10px">
                    <img
                      src="@/assets/newSite/office/data.png"
                      style="width: 100%"
                      alt=""
                    />
                  </div>
                </div>
              </el-tab-pane>
            </el-tabs>
          </div>
        </div>
      </div>

  
      <div class="scene">
        <h4 class="pro-tit">赋能多角色办公</h4>
        <div class="container text-center">
          <div class="row row-cols-1 row-cols-lg-4 g-2 g-lg-3">
            <div class="col">
              <div class="p-3">
                <img src="@/assets/newSite/office/s1.png" alt="" />
                <h6>高层领导</h6>
              </div>
            </div>
            <div class="col">
              <div class="p-3">
                <img src="@/assets/newSite/office/s2.png" alt="" />
                <h6>中层干部</h6>
              </div>
            </div>
            <div class="col">
              <div class="p-3">
                <img src="@/assets/newSite/office/s3.png" alt="" />
                <h6>普通职工</h6>
              </div>
            </div>
            <div class="col">
              <div class="p-3">
                <img src="@/assets/newSite/office/s4.png" alt="" />
                <h6>入职新人</h6>
              </div>
            </div>
          </div>

          <div class="row"></div>
        </div>
      </div>

      <right-nav-new class="d-none d-lg-block" />
    </div>

    <NewFooter />
    <MobileFooter class="d-block d-lg-none" />
  </div>
</template>
    
    <script>
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import SwiperCore, { Autoplay } from "swiper";
SwiperCore.use([Autoplay]);

export default {
  name: "hardware",
  data() {
    return {
      activeName: "1",
      value: "0",
      tabType: 0,
      tab: 0,
      tabUl: 0,
      swiperOptionsCourse: {
        // height: 100,
      },
      swiperOptionsCoursePanel: {
        // height: 100,
      },
      active: "#home",
      scrollIntoViewOptions: {
        block: "start",
        behavior: "smooth",
      },
      distance_team: 0,
      distance_contact: 0,
      distance_join: 0,
    };
  },
  components: {
    Swiper,
    SwiperSlide,
  },
  computed: {
    sty() {
      let num = Number(this.value) * 100;
      return `transform: translateX(-${num / 3}%);transition: all 0.3s;`;
    },

    mySwiper() {
      return this.$refs.swiperCourse.$swiper;
    },
    coursePanel() {
      return this.$refs.coursePanel.$swiper;
    },
  },
  methods: {
    hadletab(v) {
      this.value = v;
    },
    handleTest(){
     window.open('https://apipecloud.veiban.com/login')
    },
    handleClick(tab, event) {
      console.log(tab, event);
    },
    handleLeft() {
      const arrs = ["0", "1", "2", "3", "4", "5", "6"];
      const current = this.activeName;
      let posIdx = arrs.findIndex((n) => n == current);
      if (posIdx == 0) return;
      this.activeName = arrs[posIdx - 1];
    },
    handleRight() {
      const arrs = ["0", "1", "2", "3", "4", "5", "6"];
      const current = this.activeName;
      let posIdx = arrs.findIndex((n) => n == current);
      if (current == arrs.length - 1) return;
      this.activeName = arrs[posIdx + 1];
    },
    switchTab(index) {
      this.tabType = index;
      let e = $(".panel-nav").find("div");
      e = e[index];
      $(e).siblings().removeClass("active");
      $(e).addClass("active");
      this.coursePanel.slideTo(index, 1000, false);
    },
    switchType(index) {
      let e = $(".banner-nav").find("li")[index];
      $(e).siblings().removeClass("active");
      $(e).addClass("active");
      this.mySwiper.slideTo(index, 1000, false);
    },
    windowHeight() {
      var de = document.documentElement;
      console.log(de);
      return (
        self.innerHeight ||
        (de && de.offsetHeight) ||
        document.body.offsetHeight
      );
    },
    toTarget(target) {
      this.active = target;
      const toElement = document.querySelector(target);
      toElement.scrollIntoView(this.scrollIntoViewOptions);
    },
    onScroll() {
      let scrolled = Math.abs(
        document.getElementById("appp").getBoundingClientRect().top
      );
      if (scrolled < this.distance_team) {
        this.active = "#home";
      } else if (
        scrolled >= this.distance_team &&
        scrolled < this.distance_contact
      ) {
        this.active = "#team";
      } else if (
        scrolled >= this.distance_contact &&
        scrolled < this.distance_join
      ) {
        this.active = "#join";
      }
    },
    tabChange(e) {
      let tabid = e.target.id;
      if (tabid === this.tabUl || !tabid) return;
      var ul = document.querySelector(".ullist");
      console.log(tabid);
      ul.children[tabid].classList.remove("list2");
      ul.children[tabid].classList.add("list1");
      ul.children[this.tabUl].classList.remove("list1");
      ul.children[this.tabUl].classList.add("list2");
      this.tabUl = tabid;
      this.tab = tabid;
    },
  },
  destroyed() {
    window.removeEventListener("scroll", this.onScroll, false);
  },
};
</script>
    
    <style lang="less"  >
.apipe-cloud-course-container-office {
  .top-banner {
    height: 600px;
    background-image: url("../../assets/newSite/office.png");
    background-size: cover;
    background-repeat: no-repeat;
    .container {
      color: white;
      display: flex;
      flex-direction: column;
      justify-content: center;
      height: 100%;
      .tit {
        font-size: 16px;
      }
      .exp {
        margin-top: 2rem;
        font-size: 14px;
        border: 1px solid white;
        display: inline-block;
        width: 80px;
        padding: 2px 0;
        text-align: center;
        &:hover {
          cursor: pointer;
        }
      }
    }
  }
  .mobile-banner {
    height: 200px;
    background-image: url("../../assets/newSite/office.png");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: top;
    .container {
      color: white;
      padding: 1rem 1rem 1rem 1.2rem;
      display: flex;
      flex-direction: column;
      justify-content: center;
      height: 100%;
      .tit {
        font-size: 12px;
      }
      .exp {
        margin-top: 1rem;
        font-size: 12px;
        border: 1px solid white;
        display: inline-block;
        width: 80px;
        text-align: center;
      }
    }
  }

  .customeValue {
    margin: 50px 0;
    .pro-tits {
      color: #666;
      margin-bottom: 30px;
      padding: 0 10px;
    }
    .col {
      .p-3 {
        // border: 1px solid red;
        min-height: 200px;
        box-shadow: 0px 2px 20px rgba(0, 0, 0, 0.1);
      }
    }

    img {
      width: 50px;
      height: 50px;
    }
    p {
      color: #888;
      font-size: 13px;
    }
    h6 {
      margin-top: 1rem;
    }
  }

  .sys {
    margin-bottom: 30px;
    .pro-tit {
      margin-bottom: 20px;
    }
  }

  .pc-function {
    background: #f5f6f7;
    padding: 30px 0;
    margin-bottom: 30px;
    .container {
      .pro-tit {
        position: relative;
        .opt {
          display: inline-block;
          height: 50px;
          width: 50px;
          line-height: 50px;
          border-radius: 50%;
          text-align: center;
          border: 1px solid #ccc;
          &:hover {
            cursor: pointer;
          }
        }
        .el-icon-arrow-left {
          position: absolute;
          left: 0;
          bottom: -56px;
          z-index: 99;
        }
        .el-icon-arrow-right {
          position: absolute;
          right: 0;
          bottom: -56px;
          z-index: 99;
        }

        .activ {
          border: 1px solid rgba(20, 132, 255, 1);
          color: rgba(20, 132, 255, 1);
        }
        .dis {
          cursor: not-allowed !important;
        }
      }
      .main-cot {
        display: flex;
        align-items: center;
        justify-content: space-evenly;

        .myTab {
          .el-tabs__nav-scroll .el-tabs__nav {
            width: 100% !important;

            .el-tabs__active-bar {
              background-color: transparent !important;
            }
            .el-tabs__item {
              padding: 0 30px;
            }
          }
          .el-tabs__nav-wrap::after {
            height: 0 !important;
          }

          .wrap {
            height: 400px;
            display: flex;
            padding: 30px;
            text-align: left;
            // border: 1px solid red;
            width: 1200px;
            .sp {
              font-size: 16px;
              color: #333333;
              // font-weight: 700;
            }
            .w-cot {
              font-size: 14px;
              color: #666666;
              margin-top: 10px;
              margin-bottom: 30px;
            }
          }
        }
      }
    }
  }

  .mobile-function {
    background: #f5f6f7;
    padding: 30px 0;
    margin-bottom: 30px;
    .container {
      .pro-tit {
        position: relative;
        .opt {
          display: inline-block;
          height: 50px;
          width: 50px;
          line-height: 50px;
          border-radius: 50%;
          text-align: center;
          border: 1px solid #ccc;
          &:hover {
            cursor: pointer;
          }
        }
        .el-icon-arrow-left {
          position: absolute;
          left: 0;
          bottom: -56px;
          z-index: 99;
        }
        .el-icon-arrow-right {
          position: absolute;
          right: 0;
          bottom: -56px;
          z-index: 99;
        }

        .activ {
          border: 1px solid rgba(20, 132, 255, 1);
          color: rgba(20, 132, 255, 1);
        }
        .dis {
          cursor: not-allowed !important;
        }
      }
      .main-cot {
        display: flex;
        align-items: center;
        justify-content: space-evenly;

        .myTab {
          width: 100%;
          .el-tabs__nav-scroll .el-tabs__nav {
            // width: 100% !important;

            .el-tabs__active-bar {
              background-color: transparent !important;
            }
            .el-tabs__item {
              padding: 0 30px;
            }
          }
          .el-tabs__nav-wrap::after {
            height: 0 !important;
          }

          .wrap {
            text-align: left;
            .sp {
              font-size: 16px;
              color: #333333;
            }
            .w-cot {
              font-size: 14px;
              color: #666666;
              margin-top: 10px;
              margin-bottom: 30px;
            }
          }
        }
      }
    }
  }

  .scene {
    margin: 50px 0;
    .pro-tit {
      margin-bottom: 30px;
    }
    .col {
      .p-3 {
        min-height: 200px;
        box-shadow: 0px 2px 20px rgba(0, 0, 0, 0.1);
      }
    }
    img {
      width: 100%;
      height: auto;
    }
    h6 {
      margin-top: 1rem;
    }
  }
}

.content-title {
  width: 100%;
  background-color: #f3f8ff;
  height: 60px;
}
.content-title ul {
  max-width: 1240px;
  margin: 0 auto;
}
.content-title ul li {
  float: left;
  width: 64px;
  height: 60px;
  opacity: 1;
  font-size: 16px;
  font-family: SourceHanSansCN, SourceHanSansCN-Medium;
  font-weight: 500;
  text-align: right;
  color: #657687;
  line-height: 24px;
  margin-right: 126px;
}
//   #appp {
//     /* 关键代码，需要给容器添加高度 */
//     position: relative;
//     width: 100%;
//   }
.content-title ul li a {
  display: block;
  width: 100%;
  height: 60px;
  text-align: center;
  line-height: 60px;
  color: #808d9f;
  text-decoration: none;
}
.content-title ul li a:hover,
.content-title ul li a:active {
  color: #1584ff;
}
.content-title ul li a.active {
  color: #1584ff;
  border-bottom: 1px solid blue;
}
.Pain_pointbox {
  position: relative;
}
.Pain_pointbox_img {
  width: 100%;
  height: 100%;
}
#home,
#join,
#team {
  //   max-width: 1282px;
  width: 100%;
  margin: 0 auto;
  //   height: 200px;
  //   color: #ffff;
  font-size: 30px;
  text-align: center;
  //   border: 1px solid black;
  //   line-height: 200px;
  padding-top: 60px;
}
.Industry_background_box {
  position: relative;
  height: 650px;
  padding-left: 17%;
}
.Industry_background_top {
  height: 126px;
  max-width: 1281px;
  opacity: 1;
}
.Industry_background_top_p1 {
  height: 34px;
  opacity: 1;
  font-size: 34px;
  font-family: SourceHanSansCN, SourceHanSansCN-Medium;
  font-weight: 500;
  color: #2c323b;
  line-height: 51px;
  margin: 16px 0px 50px 0px;
}
.Industry_background_top_p2 {
  height: 61px;
  opacity: 1;
  font-size: 18px;
  font-family: SourceHanSansCN, SourceHanSansCN-Regular;
  font-weight: 400;
  text-align: center;
  color: #7d7d7d;
  line-height: 24px;
}
.problem {
  max-width: 1281px;
  margin-top: 120px;
}
#join {
  background: #f3f8ff;
  //   padding-bottom: 70px;
  height: 717px;
}
#join .title {
  height: 14px;
  opacity: 1;
  font-size: 1vh;
  font-family: SourceHanSansCN, SourceHanSansCN-Normal;
  font-weight: Normal;
  text-align: center;
  color: #657687;
  padding-top: 5px;
}
#join .title2 {
  font-size: 4vh;
  font-family: SourceHanSansCN, SourceHanSansCN-Medium;
  font-weight: 500;
  // text-align: justify;
  color: #262c33;
}

.clickbtn {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  left: 37%;
  top: 55%;
  border-radius: 30px;
  width: 9vw;
  height: 6vh;
  font-size: 2vh;
  border: 1px solid #1584ff;
  background-color: #1584ff;
  color: white;
  cursor: pointer//鼠标悬浮变 手;
}
.problem_box {
  display: flex;
  max-width: 1281px;
  // flex-wrap: wrap;
  justify-content: space-between;
  margin-top: 60px;
}
.problem_box1 {
  width: 25%;
  height: 298px;
  opacity: 1;
  background: #fbfcfd;
  border-radius: 10px;
  margin: 5px;
  position: relative;
}
.problem_box1_img {
  margin: 30px 0px 20px 0px;
}
.problem_box1_title {
  height: 24px;
  opacity: 1;
  font-size: 24px;
  font-family: SourceHanSansCN, SourceHanSansCN-Medium;
  font-weight: 500;
  text-align: center;
  color: #3a3a3a;
  line-height: 36px;
  margin-bottom: 20px;
}
.problem_box1_content {
  height: 81px;
  opacity: 1;
  font-size: 18px;
  font-family: SourceHanSansCN, SourceHanSansCN-Regular;
  font-weight: 400;
  text-align: justify;
  color: #3a3a3a;
  line-height: 27px;
  margin: 22px;
}
.problem_box1:hover {
  background-color: white;
  box-shadow: 0px 4px 18px 0px #daebff;
  border-bottom: 15px solid #1584ff;
}
#team {
  position: relative;
}
.Mechanics_5_img {
  position: relative;
}
.introduce {
  position: absolute;
  top: 19%;
  left: 39%;
}
.introduce .p1 {
  height: 34px;
  opacity: 1;
  font-size: 34px;
  font-family: SourceHanSansCN, SourceHanSansCN-Medium;
  font-weight: 500;
  color: #ffffff;
  line-height: 51px;
}
.introduce .p2 {
  height: 20px;
  opacity: 1;
  font-size: 20px;
  font-family: SourceHanSansCN, SourceHanSansCN-Regular;
  font-weight: 400;
  text-align: center;
  color: #ffffff;
  line-height: 30px;
  margin-top: 27px;
}
.introduce2 {
  position: absolute;
  top: 35%;
  left: 20%;
}
.introduce2_box {
  display: flex;
}
.introduce2_box_content {
  display: flex;
  width: 30vw;
  height: 195px;
  opacity: 1;
  background: #ffffff;
  border-radius: 10px;
  margin-bottom: 20px;
}
.introduce2_box_content1 {
  display: flex;
  width: 30vw;
  height: 195px;
  opacity: 1;
  background: #ffffff;
  border-radius: 10px;
  margin-left: 19px;
  margin-bottom: 20px;
}
.introduce2_box_content_img {
  margin-right: 25px;
  margin-top: 47px;
  margin-left: 50px;
}
.introduce2_box_content1_img {
  margin-right: 25px;
  margin-top: 47px;
  margin-left: 50px;
}
.introduce2_box_content_title {
  text-align: left;
  margin-right: 59px;
}
.introduce2_box_content_title .p1 {
  height: 24px;
  opacity: 1;
  font-size: 24px;
  font-family: SourceHanSansCN, SourceHanSansCN-Medium;
  font-weight: 500;
  text-align: left;
  color: #333333;
  line-height: 36px;
  padding-top: 40px;
}
.introduce2_box_content_title .p2 {
  opacity: 1;
  font-size: 18px;
  font-family: SourceHanSansCN, SourceHanSansCN-Regular;
  font-weight: 400;
  text-align: justify;
  color: #333333;
  padding-top: 45px;
}
.introduce2_box_content1_title {
  text-align: left;
  margin-right: 59px;
}
.introduce2_box_content1_title .p1 {
  height: 24px;
  opacity: 1;
  font-size: 24px;
  font-family: SourceHanSansCN, SourceHanSansCN-Medium;
  font-weight: 500;
  text-align: left;
  color: #333333;
  line-height: 36px;
  padding-top: 40px;
}
.introduce2_box_content1_title .p2 {
  opacity: 1;
  font-size: 18px;
  font-family: SourceHanSansCN, SourceHanSansCN-Regular;
  font-weight: 400;
  text-align: justify;
  color: #333333;
  padding-top: 45px;
}
.function {
  height: 51px;
  opacity: 1;
  font-size: 34px;
  font-family: SourceHanSansCN, SourceHanSansCN-Medium;
  font-weight: 500;
  color: #262c33;
  line-height: 51px;
  margin-top: 60px;
}
.function_content {
  display: flex;
  margin: 0% 15%;
  //   border: 1px solid #1584ff;
}
.ulli {
  padding-left: 8%;
  height: 50%;
  padding-top: 7%;
}
.function_content_box {
  display: flex;
  width: 100%;
  margin-left: 10%;
}
.function_content_box_content {
  text-align: left;
  padding-top: 8%;
  width: 20vw;
}
.function_content_box_content .p1 {
  height: 21px;
  opacity: 1;
  font-size: 14px;
  font-family: SourceHanSansCN, SourceHanSansCN-Normal;
  font-weight: Normal;
  text-align: left;
  color: #657687;
  line-height: 21px;
}
.function_content_box_content .p2 {
  height: 36px;
  opacity: 1;
  font-size: 24px;
  font-family: SourceHanSansCN, SourceHanSansCN-Medium;
  font-weight: 500;
  text-align: justify;
  color: #262c33;
  line-height: 36px;
  margin-bottom: 20px;
  margin-top: 15px;
}
.function_content_box_content .p3,
.function_content_box_content .p4,
.function_content_box_content .p5,
.function_content_box_content .p6 {
  //   height: 33px;
  opacity: 1;
  font-size: 18px;
  font-family: SourceHanSansCN, SourceHanSansCN-Regular;
  font-weight: 400;
  text-align: left;
  color: #333333;
  line-height: 26px;
  margin-top: 20px;
}
 
</style>
    